<template>
	<div class="row space-y-1 bg-white p-4">
		<div class="col-md-6 mt-3">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap"
					>{{ $t("brandName")
					}}<span
						class="help-tooltip cursor-pointer"
						:title="$t('brandNameHelpText')"
					></span
				></label>
				<div>
					<input
						data-cy="band-name"
						type="text"
						v-model="billing.brand_name"
						class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
					/>
				</div>
			</div>
		</div>
		<div class="col-md-6 mt-3">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap"
					>{{ $t("storeDescription")
					}}<span
						class="help-tooltip cursor-pointer"
						:title="$t('storeDescriptionHelpText')"
					></span
				></label>
				<div>
					<input
						type="text"
						v-model="billing.store_description"
						class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
					/>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group mt-1">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap"
				>
					<input
						data-cy="post-invoice"
						type="checkbox"
						id="postInvoice"
						v-model="billing.post_invoice"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("postInvoice") }}
						<span
							class="help-tooltip cursor-pointer"
							:title="$t('postInvoiceToFortnoxHelpText')"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<input
						data-cy="bank-id-threshold-amount"
						type="checkbox"
						v-model="
							billing.use_accounting_customer_number_for_identifier
						"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("use_accounting_customer_number_for_identifier")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="
								$t(
									'use_accounting_customer_number_for_identifier_text'
								)
							"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap mb-1"
				>
					<input
						data-cy="bank-id-all"
						type="checkbox"
						id="requireBankIDforAllOrders"
						v-model="billing.require_bank_id_for_all_orders"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("requireBankIDforAllOrders")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="
								$t('requireBankIDforAllOrdersHelpText')
							"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<input
						data-cy="bank-id-threshold-amount"
						type="checkbox"
						id="requireBankIDThresholdAmount"
						@change="
							changeBooleanField(
								'require_bank_id_for_for_orders_above_certain_amount',
								'bankid_order_amount'
							)
						"
						v-model="
							billing.require_bank_id_for_for_orders_above_certain_amount
						"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("requireBankIDThresholdAmount")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="
								$t(
									'require_bank_id_for_for_orders_above_certain_amountHelpText'
								)
							"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<input
						data-cy="bank-id-threshold-amount"
						type="checkbox"
						v-model="billing.send_invoices_via_noxfinance"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("send_invoices_to_nox_finance")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="$t('send_to_noxHelpText')"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label class="label-w-input col-form-label font-light mb-1">
					<input
						data-cy="import_all_orders_from_accounting"
						type="checkbox"
						v-model="billing.import_all_orders_from_accounting"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("import_all_orders_from_accounting")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="
								$t('import_all_orders_from_accounting')
							"
						></span
					></span>
				</label>
				<label
					class="ml-2"
					v-if="billing.import_all_orders_from_accounting"
				>
					<el-date-picker
						size="mini"
						v-model="billing.import_all_orders_from_start_date"
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
					>
					</el-date-picker>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap mb-1"
				>
					<input
						data-cy="apply-display_store_to_all_buyers"
						type="checkbox"
						id="applyDisplayStoreToAllBuyers"
						v-model="billing.display_store_to_all_buyers"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("display_store_to_all_buyers")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="
								$t('apply_display_store_to_all_buyers')
							"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap mb-1"
				>
					<input
						data-cy="apply-prices_given_ink_vat"
						type="checkbox"
						id="applyDisplayStoreToAllBuyers"
						v-model="billing.prices_given_ink_vat"
						class="checkbox-height h-4 w-4"
					/>
					<span class="ml-2"
						>{{ $t("prices_given_ink_vat")
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="$t('apply_prices_given_ink_vat')"
						></span
					></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap mb-1"
					>{{ $t("chooseCompanyLogo")
					}}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('chooseCompanyLogoHelpText')"
					></span
				></label>
				<div class="upload-pic-field">
					<div class="mr-3" data-cy="company-photo-upload">
						<img
							class="rounded-circle"
							:src="croppedImage"
							alt="automatisera | preview"
							width="42"
							v-if="croppedImage"
						/>
						<img
							class="rounded-circle"
							src="./../../../../assets/images/preview/preview.png"
							alt="automatisera | preview"
							width="42"
							v-else
						/>
					</div>
					<el-button
						slot="trigger"
						size="small"
						type="default"
						@click="openLogoModal"
						class="btn-upload w-36"
						style="background: #eeeeee; border: none"
					>
						{{ $t("selectFile") }}
					</el-button>
					<!--					<button class="btn-upload w-36" @click="openLogoModal">-->
					<!--						{{ $t('selectFile') }}-->
					<!--					</button>-->
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap"
					>{{ $t("minimum_order_amount")
					}}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('minimum_order_amount_text')"
					></span
				></label>
				<div>
					<input
						data-cy="minimum_order_amount"
						type="number"
						id="minimum_order_amount"
						min="0"
						style="width: 60% !important"
						v-model="billing.minimum_order_amount"
						class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
					/>
				</div>
			</div>
		</div>
		<div class="col-md-6" v-if="systemMode === 'whitelabel'">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap mb-1"
					>{{ $t("fullLogo")
					}}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="``"
					></span
				></label>
				<div class="upload-pic-field">
					<div class="mr-3" data-cy="">
						<img
							class="rounded-circle"
							:src="fullLogoImage"
							alt="automatisera | preview"
							width="42"
							v-if="fullLogoImage"
						/>
						<img
							class="rounded-circle"
							src="./../../../../assets/images/preview/preview.png"
							alt="automatisera | preview"
							width="42"
							v-else
						/>
					</div>

					<div>
						<el-upload
							class="upload--emo"
							:on-change="FullImageOnchange"
							:file-list="fullLogoList"
							:limit="1"
							action="https://jsonplaceholder.typicode.com/posts/"
							:auto-upload="false"
						>
							<el-button
								slot="trigger"
								size="small"
								type="default"
								class="btn-upload w-36"
								style="background: #eeeeee; border: none"
							>
								{{ $t("selectFile") }}
							</el-button>
							<div class="el-upload__tip" slot="tip">
								{{
									$t("whiteLabelSection.fullImageTextCaption")
								}}
							</div>
						</el-upload>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6" v-if="systemMode === 'whitelabel'">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap mb-1"
					>{{ $t("halfLogo")
					}}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="``"
					></span
				></label>
				<div class="upload-pic-field">
					<div class="mr-3" data-cy="company-photo-upload">
						<img
							class="rounded-circle"
							:src="halfLogoImage"
							alt="automatisera | preview"
							width="42"
							v-if="halfLogoImage"
						/>
						<img
							class="rounded-circle"
							src="./../../../../assets/images/preview/preview.png"
							alt="automatisera | preview"
							width="42"
							v-else
						/>
					</div>
					<div>
						<el-upload
							class="upload-half-demo"
							:file-list="halfLogoList"
							:limit="1"
							:on-remove="handleHalfRemove"
							:on-change="halfLogoImageOnchange"
							action="https://jsonplaceholder.typicode.com/posts/"
							:auto-upload="false"
						>
							<el-button
								slot="trigger"
								size="small"
								type="default"
								class="btn-upload w-36"
								style="background: #eeeeee; border: none"
							>
								{{ $t("selectFile") }}
							</el-button>
							<div class="el-upload__tip" slot="tip">
								{{
									$t("whiteLabelSection.halfImageTextCaption")
								}}
							</div>
						</el-upload>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6" v-if="systemMode === 'whitelabel'">
			<div class="form-group">
				<label
					class="flex align-items-center col-form-label font-light whitespace-nowrap mb-1"
					>{{ $t("whiteLabelSection.favIcon")
					}}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="``"
					></span
				></label>
				<div class="upload-pic-field">
					<div class="mr-3" data-cy="company-photo-upload">
						<img
							class="rounded-circle"
							:src="favIcon"
							alt="automatisera | preview"
							width="42"
							v-if="favIcon"
						/>
						<img
							class="rounded-circle"
							src="./../../../../assets/images/preview/preview.png"
							alt="automatisera | preview"
							width="42"
							v-else
						/>
					</div>
					<div>
						<el-upload
							class="upload-fav-demo"
							:file-list="favIconList"
							:limit="1"
							:on-remove="handleFavIconRemove"
							:on-change="FavIconOnchange"
							action="https://jsonplaceholder.typicode.com/posts/"
							:auto-upload="false"
						>
							<el-button
								slot="trigger"
								size="small"
								type="default"
								class="btn-upload w-36"
								style="background: #eeeeee; border: none"
							>
								{{ $t("selectFile") }}
							</el-button>
							<div class="el-upload__tip" slot="tip">
								{{ $t("whiteLabelSection.favIconTextCaption") }}
							</div>
						</el-upload>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 mt-5 mb-4">
			<span class="flex justify-center">
				<button
					data-cy="setting-btn"
					@click="updateSettings"
					:disabled="updating"
					:style="
						GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
							? 'background:' + GET_CUSTOMIZATION.button
							: ''
					"
					:class="
						GET_CUSTOMIZATION && GET_CUSTOMIZATION.button
							? ''
							: 'bg-green-500'
					"
					class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3"
				>
					<span
						v-if="updating"
						class="flex align-items-center space-x-3"
					>
						<svg
							class="animate-spin mr-2 h-5 w-5 text-white"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								class="opacity-25"
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								stroke-width="4"
							></circle>
							<path
								class="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							></path>
						</svg>
						{{ $t("updating") }}
					</span>
					<span v-else>{{ $t("update") }}</span>
				</button>
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { DatePicker } from "element-ui";
import Vue from "vue";
import bus from "../../../../bus";

Vue.use(DatePicker);
export default {
	name: "generalSetting",
	data: function () {
		return {
			systemMode: "",
			billing: {
				name: "",
				organization_number: "",
				address: "",
				city: "",
				phone_number: "",
				post_invoice: false,
				shop_url: "",
				contact_email: "",
				id: "",
				zip_code: "",
				invoice_email: "",
				brand_name: "",
				invite_email_subject: "",
				invite_email_header: "",
				invite_email_content: "",
				order_confirmation_text: "",
				invite_email_subject_en: '',
				invite_email_header_en: '',
				invite_email_content_en: '',
				order_confirmation_text_en: '',
				use_accounting_customer_number_for_identifier: "",
				menu_color: "",
				button_color: "",
				link_color: "",
				price_list: {
					id: "",
				},
				bankgiro: "",
				plusgiro: "",
				iban: "",
				apply_specific_customer_max_credit: false,
				apply_general_max_credit: false,
				general_max_credit_amount: 0,
				apply_stock_threshold: false,
				stock_threshold_quantity: 0,
				require_bank_id_for_all_orders: false,
				display_store_to_all_buyers: false,
				prices_given_ink_vat: false,
				require_bank_id_for_for_orders_above_certain_amount: 0,
				send_invoices_via_noxfinance: 0,
				bankid_order_amount: 0,
				minimum_order_amount: 0,
				hide_stock_quantity: 0,
				admin_email_address: "",
				send_order_notifications_to_admin: false,
				disable_customer_order_conf_emails: false,
				show_standard_price_additional_to_customer_specific_price: false,
				customer_invite_rule: "",
				import_all_orders_from_start_date: "",
				import_all_orders_from_accounting: "",
			},
			croppedImage: null,
			updating: false,
			halfLogoImage: null,
			favIcon: null,
			fullLogoImage: false,
			fullLogoList: [],
			halfLogoList: [],
			favIconList: [],
			companyLogoObject: null,
		};
	},
	methods: {
		changeBooleanField(s, e) {
			if (!this.billing[s]) {
				this.billing[e] = 0;
			}
		},
		openLogoModal() {
			this.$store.commit("utility/OPEN_PHOTO_MODAL");
		},
		updateSettings() {
			if (
				this.billing.brand_name === "" ||
				this.billing.store_description === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
				return false;
			}
			this.updating = true;
			const payload = {
				brand_name: this.billing.brand_name,
				minimum_order_amount: this.billing.minimum_order_amount,
				store_description: this.billing.store_description,
				post_invoice: this.billing.post_invoice,
				use_accounting_customer_number_for_identifier:
					this.billing.use_accounting_customer_number_for_identifier,
				require_bank_id_for_all_orders:
					this.billing.require_bank_id_for_all_orders,
				require_bank_id_for_for_orders_above_certain_amount:
					this.billing
						.require_bank_id_for_for_orders_above_certain_amount,
				send_invoices_via_noxfinance:
					this.billing.send_invoices_via_noxfinance,
				import_all_orders_from_accounting:
					this.billing.import_all_orders_from_accounting,
				import_all_orders_from_start_date:
					this.billing.import_all_orders_from_start_date,
				prices_given_ink_vat: this.billing.prices_given_ink_vat,
			};
			this.$store
				.dispatch("portal/updateCompanySettings", payload)
				.then((res) => {
					this.updating = false;
					if (this.companyLogoObject) {
						let formdata = new FormData();
						formdata.append("file", this.companyLogoObject);
						this.$store
							.dispatch("portal/uploadCompanyLogo", formdata)
							.then(() => {
								this.companyLogoObject = null;
							});
					}
					if (
						this.fullLogoList.length > 0 ||
						this.halfLogoList.length > 0 ||
						this.favIconList.length > 0
					) {
						this.uploadCompanyImages();
					}
					this.$services.helpers.notification(
						this.$t("successCompanyupdated"),
						"success",
						this
					);
				})
				.catch((err) => {
					this.updating = false;
					window.Bus.$emit("output-error", err);
				});
		},
		getCompanyDetails() {
			this.$store
				.dispatch("portal/getCompanyDetails")
				.then((res) => {
					this.billing = res.data;
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		uploadCompanyImages() {
			const formData = new FormData();
			if (this.fullLogoList.length > 0) {
				formData.append("full_logo", this.fullLogoList[0]);
			}
			if (this.halfLogoList.length > 0) {
				formData.append("half_logo", this.halfLogoList[0]);
			}
			if (this.favIconList.length > 0) {
				formData.append("fav_icon_logo", this.favIconList[0]);
			}

			this.$store
				.dispatch("portal/uploadCompanyImages", formData)
				.then((res) => {
					this.updating = false;
					window.Bus.$emit("update-company-images");
					this.fullLogoList = [];
					this.halfLogoList = [];
					this.favIconList = [];
					if (this.fullLogoList.length > 0) {
						setTimeout(function () {
							document
								.getElementsByClassName("el-icon-close")[0]
								.click();
						}, 500);
					}
					if (this.halfLogoList.length > 0) {
						setTimeout(function () {
							document
								.getElementsByClassName("upload-half-demo")[0]
								.getElementsByClassName("el-icon-close")[0]
								.click();
						}, 500);
					}
					if (this.favIconList.length > 0) {
						setTimeout(function () {
							document
								.getElementsByClassName("upload-fav-demo")[0]
								.getElementsByClassName("el-icon-close")[0]
								.click();
						}, 500);
					}

					// this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
				})
				.catch((err) => {
					this.updating = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		FullImageOnchange(files) {
			const fileType = ["png", "jpg", "jpeg"];
			const file = files.raw.type.split("/")[1];
			const limit = 1 - this.fullLogoList.length;
			const self = this;
			if (limit > 0) {
				if (fileType.includes(file)) {
					const img = new Image();
					img.src = window.URL.createObjectURL(files.raw);
					img.onload = () => {
						if (img.width <= 200 && img.height <= 200) {
							self.fullLogoList.push(files.raw);
							const reader = new FileReader();
							reader.onload = function (e) {
								self.fullLogoImage = e.target.result;
							};
							reader.readAsDataURL(files.raw);
						} else {
							this.fullLogoList.pop();
							setTimeout(function () {
								document
									.getElementsByClassName("el-icon-close")[0]
									.click();
							}, 500);
							return this.$services.helpers.notification(
								this.$t("invalidFormat"),
								"error",
								this
							);
						}
					};
				} else {
					return this.$services.helpers.notification(
						this.$t("invalidFormat"),
						"error",
						this
					);
				}
			} else {
				if (this.fullLogoList.length > 0) {
					this.fullLogoList.pop();
				}
			}
		},
		halfLogoImageOnchange(files) {
			const fileType = ["png", "jpg", "jpeg"];
			const file = files.raw.type.split("/")[1];
			const limit = 1 - this.halfLogoList.length;
			const self = this;
			if (limit > 0) {
				if (fileType.includes(file)) {
					const img = new Image();
					img.src = window.URL.createObjectURL(files.raw);
					img.onload = () => {
						if (img.width <= 100 && img.height <= 100) {
							self.halfLogoList.push(files.raw);
							const reader = new FileReader();
							reader.onload = function (e) {
								self.halfLogoImage = e.target.result;
							};
							reader.readAsDataURL(files.raw);
						} else {
							this.halfLogoList.pop();
							setTimeout(function () {
								document
									.getElementsByClassName(
										"upload-half-demo"
									)[0]
									.getElementsByClassName("el-icon-close")[0]
									.click();
							}, 500);
							return this.$services.helpers.notification(
								this.$t("invalidDimension"),
								"error",
								this
							);
						}
					};
				} else {
					return this.$services.helpers.notification(
						this.$t("invalidDimension"),
						"error",
						this
					);
				}
			} else {
				if (this.halfLogoList.length > 0) {
					this.halfLogoList.pop();
				}
			}
		},
		handleHalfRemove(file) {
			this.halfLogoList.pop();
		},
		FavIconOnchange(files) {
			const fileType = ["png", "jpg", "jpeg"];
			const file = files.raw.type.split("/")[1];
			const limit = 1 - this.favIconList.length;
			const self = this;
			if (limit > 0) {
				if (fileType.includes(file)) {
					const img = new Image();
					img.src = window.URL.createObjectURL(files.raw);
					img.onload = () => {
						if (img.width <= 16 && img.height <= 16) {
							self.favIconList.push(files.raw);
							const reader = new FileReader();
							reader.onload = function (e) {
								self.favIcon = e.target.result;
							};
							reader.readAsDataURL(files.raw);
						} else {
							this.favIconList.pop();
							setTimeout(function () {
								document
									.getElementsByClassName(
										"upload-fav-demo"
									)[0]
									.getElementsByClassName("el-icon-close")[0]
									.click();
							}, 500);
							return this.$services.helpers.notification(
								this.$t("invalidDimension"),
								"error",
								this
							);
						}
					};
				} else {
					return this.$services.helpers.notification(
						this.$t("invalidDimension"),
						"error",
						this
					);
				}
			} else {
				if (this.favIconList.length > 0) {
					this.favIconList.pop();
				}
			}
		},
		handleFavIconRemove(file) {
			this.favIconList.pop();
		},
		getCompanySubscription: function () {
			this.$store
				.dispatch("portal/getCompanySubscription")
				.then((resp) => {
					this.favIcon = resp.data.data.fav_icon_logo;
					this.halfLogoImage = resp.data.data.half_logo;
					this.fullLogoImage = resp.data.data.full_logo;
				});
		},
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
		}),
	},
	mounted() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE;
		this.getCompanyDetails();
		this.getCompanySubscription();
		let self = this;
		window.Bus.$on("logo-image", function (e) {
			self.companyLogoObject = e.logo;
			self.croppedImage = e.base64;
		});
	},
};
</script>

<style scoped></style>
